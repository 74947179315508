import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const ListLink = props => (
    <li style={{ display: `inline-block`, marginRight: `1rem` }}>
        <Link to={props.to}>{props.children}</Link>
    </li>
)

export default function Layout({ children }) {
    const data = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
          }
        `
      )
    return (
    <div style={{ margin: `3rem auto`, maxWidth: 1000, padding: `0 1rem` }}>
      <header style={{ marginBottom: `1.5rem` }}>
          <Link to="/" style={{ textShadow: `none`, backgroundImage: `none`}}>
              <h1 style={{ display: `inline` }}>{data.site.siteMetadata.title}</h1>
          </Link>
          <ul style={{ listStyle: `none`, float: `right` }}>
              <ListLink to="/daily-fantasy-football-blog/What-Is-NFL-DFS/">What is NFL DFS?</ListLink>
              <ListLink to="/">Blog</ListLink>
              <ListLink to="/about/">About</ListLink>
              <ListLink to="/contact/">Contact</ListLink>
          </ul>
      </header>
      {children}

      <footer style={{ marginBottom: `1.5rem`, marginTop: `5rem` }}>
          
          <ul style={{ listStyle: `none`, display: `inline`, textTransform: `none`, verticalAlign: `sub`}}>
                <ListLink to="/daily-fantasy-football-blog/What-Is-NFL-DFS/">What is NFL DFS?</ListLink>
              <ListLink to="/">Blog</ListLink>
              <ListLink to="/about/">About</ListLink>
              <ListLink to="/contact/">Contact</ListLink>
          </ul>
          <Link to="/" style={{ textShadow: `none`, backgroundImage: `none`}}>
              <h1 style={{ display: `inline`, float: `right`}}>{data.site.siteMetadata.title}</h1>
          </Link>
      </footer>
    </div>
  )
}